import { axiosUserInstance } from './axiosInstance';
import {getCategory ,getCategoryById} from './api'

const token = localStorage.getItem('authToken');

  export const fetchCategories = async () => {

    try {
      const response = await axiosUserInstance.get(getCategory);
      return response.data;
    } catch (error) {
      console.error("Error fetching categories:", error);
      throw error;
    }
  };

  export const getGategoryById = async (id) => {
    try {
      //console.log('product id : ',id)
      const response = await axiosUserInstance.get(getCategoryById, {
        params: { id: id },
      });
      //console.log('response.data : ',response.data)
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error("An error occurred while fetching Category");
    }
  };