import axios from 'axios';

const baseURL = 'https://sk-anf8l.ondigitalocean.app/';

const axiosAdminInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosAdminInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const axiosUserInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export { axiosAdminInstance, axiosUserInstance };
