import React, { useState } from "react";
import { init, send } from "emailjs-com";
import rvbg from "../static/images/modern-kitchen-equipment-in-stainless-steel-design-free-photo (1).jpg";

init("OItUb_acnuiWYvS5Q");

function ReviewForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
  });
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    setError(null);
    setSuccess(null);
    console.log(formData)

    try {
      await send(
        'service_v49jo0m', // EmailJS Service ID
        'template_f2u67wk', // EmailJS Template ID
        formData
      );
      setSuccess("Your message has been sent successfully!");
      setFormData({ name: '', email: '', mobile: '' });
    } catch (err) {
      setError("There was an error sending your message. Please try again.");
    } finally {
      setSending(false);
    }
  };

  return (
    <div>
      <div className="hidden lg:block">
        <div className="relative w-full flex bg-black">

          <img src={rvbg} className="w-full object-cover" alt="Background" />

          <div className="absolute inset-0 flex flex-col items-end">
            <div className="bg-black bg-opacity-60 flex flex-col justify-center lg:justify-center lg:px-20 w-full h-full max-w-lg lg:p-10">
              <p className="text-white text-center lg:text-start text-4xl font-semibold mb-4">
                Our Product Review
              </p>
              <p className="text-white text-center lg:text-sm lg:text-start font-medium mb-4">
                Discover how our products stand out. Share your experience and
                insights with us!
              </p>
              <form className="flex flex-col w-full ps-24 lg:ps-0 pt-5 lg:bg-transparent" onSubmit={handleSubmit}>
                <div>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="mt-3 block w-3/4 border border-gray-300 shadow-sm sm:text-sm p-2 focus:outline-none"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="mt-4 block w-3/4 border border-gray-300 shadow-sm sm:text-sm p-2 focus:outline-none"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <input
                    type="tel"
                    id="mobile"
                    name="mobile"
                    className="mt-4 block w-3/4 border border-gray-300 shadow-sm sm:text-sm p-2 focus:outline-none"
                    placeholder="Mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-3/4 mt-6 text-sm bg-red-600 hover:bg-red-500 border border-red-600 hover:border-red-500 text-white font-semibold py-2 px-4 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
                    disabled={sending}
                  >
                    {sending ? "Sending..." : "Submit"}
                  </button>
                </div>
                {error && <p className="text-red-500 mt-2">{error}</p>}
                {success && <p className="text-green-500 mt-2">{success}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:hidden">
        <img src={rvbg} className="w-full object-cover" alt="Background" />
        <div className="bg-black py-10 flex flex-col justify-center w-full h-full items-center">
          <div className="w-3/4 flex flex-col justify-center items-center">
            <p className="text-white text-3xl font-semibold text-center mb-4">
              Our Product Review
            </p>
            <p className="text-white text-center text-sm font-medium mb-4">
              Discover how our products stand out. Share your experience and
              insights with us!
            </p>
          </div>
          <form className="flex flex-col pt-5 w-3/4 lg:bg-transparent" onSubmit={handleSubmit}>
            <div>
              <input
                type="text"
                id="name"
                name="name"
                className="mt-1 pt-1 w-full block border border-gray-300 shadow-sm sm:text-sm p-2 focus:outline-none"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-5 block border w-full border-gray-300 shadow-sm sm:text-sm p-2 focus:outline-none"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <input
                type="tel"
                id="mobile"
                name="mobile"
                className="mt-5 block border w-full border-gray-300 shadow-sm sm:text-sm p-2 focus:outline-none"
                placeholder="Mobile"
                value={formData.mobile}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <button
                type="submit"
                className="mt-10 bg-red-600 w-full text-white font-semibold py-2 px-4 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
                disabled={sending}
              >
                {sending ? "Sending..." : "Submit"}
              </button>
            </div>
            {error && <p className="text-red-500 mt-2">{error}</p>}
            {success && <p className="text-green-500 mt-2">{success}</p>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ReviewForm;
