import React, { useState } from "react";
import { init, send } from "emailjs-com";

init("OItUb_acnuiWYvS5Q");

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    setError(null);
    setSuccess(null);
    console.log(formData)

    try {
      await send(
        'service_qlamzmz',
        'template_xzyjmms',
        formData
      );
      setSuccess("Your message has been sent successfully!");
      setFormData({ name: '', email: '', mobile: '',message: '' });
    } catch (err) {
      setError("There was an error sending your message. Please try again.");
    } finally {
      setSending(false);
    }
  };

  return (
    <div className="w-full">
      <form className="flex flex-col w-80 md:w-64 lg:w-96 pt-5 md:pt-0 pb-10 lg:pb-0" onSubmit={handleSubmit}>
        <p className="font-semibold pb-2 text-white text-xl hidden md:block">
            Contact details
        </p>
        <div>
          <input
            type="text"
            id="name"
            name="name"
            className="mt-2 block w-full border border-gray-300 shadow-sm sm:text-sm p-2 focus:outline-none"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <input
            type="email"
            id="email"
            name="email"
            className="mt-2 block w-full border border-gray-300 shadow-sm sm:text-sm p-2 focus:outline-none"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <input
            type="tel"
            id="mobile"
            name="mobile"
            className="mt-2 block w-full border border-gray-300 shadow-sm sm:text-sm p-2 focus:outline-none"
            placeholder="Mobile"
            value={formData.mobile}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="3"
            className="mt-2 block w-full border border-gray-300 shadow-sm sm:text-sm p-2 focus:outline-none"
            placeholder="Message"
            required
          ></textarea>
        </div>
        <div>
          <button
            type="submit"
            className="w-full mt-4 text-sm bg-red-600 hover:bg-red-500 border border-red-600 hover:border-red-500 text-white font-semibold py-2 px-4 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
            disabled={sending}
          >
            {sending ? "Sending..." : "Submit"}
          </button>
        </div>
        {error && <p className="text-red-500 mt-2">{error}</p>}
        {success && <p className="text-green-500 mt-2">{success}</p>}
      </form>
    </div>
  );
}

export default ContactForm;
