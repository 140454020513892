import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import bg from "../static/images/modern-kitchen-equipment-in-stainless-steel-design-free-photo (1).jpg";
import ContactForm from "./ContactForm";
import { FaMapLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { CgMail } from "react-icons/cg";
import { FaWhatsapp } from "react-icons/fa";
import "../static/styles/styles.css";

function ContactUs() {
  const apiKey = "AIzaSyBqGIpHOCn3StNFSQ_OTnh_ZwfBqj8Eex0";
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=25.276987,55.296249&zoom=14&size=600x300&maptype=roadmap&markers=color:red%7Clabel:A%7C25.276987,55.296249&key=${apiKey}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=" flex bg-black flex-col">
      <div>
        <Navbar />
      </div>
      <div className="h-full mt-2">
        <div className="-mt-4 relative flex flex-col h-96 md:h-full">
          <img
            src={bg}
            className="w-full h-[120%] object-cover"
            alt="Background"
          />
          <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-10">
            <div className=" w-full p-8">
              {/* <p className="text-xl lg:text-4xl font-semibold text-white text-center">
                Contact
              </p> */}
            </div>
          </div>
        </div>
        <div className="bg-black flex flex-col md:flex-row md:px-5 lg:px-28 xl:px-32 pt-16 lg:pt-10 justify-center items-center md:items-start md:justify-between">
          <div className="text-white ">
            <div className="flex flex-col justify-between" />

            <div className="w-72 lg:w-96 mt-0 lg:mt-12">
              <p className="font-bold text-3xl">Connect with Us</p>
              <div className="line mt-2 bg-red-600 h-0.5 w-[300px] md:w-[350px] lg:w-[300px]"></div>

              <p className="text-xs md:text-sm pt-4 text-white text-left md:hidden">
                Have questions or feedback? Reach out to us through the form
                below we're here to help!
              </p>
              <p className="text-xs md:text-sm pt-4 text-white text-left hidden md:block">
                We’d love to hear from you! Whether you have questions,
                feedback, or just want to say hello, feel free to reach out to
                us through the form below. Our team is here to assist you and
                ensure you have the best experience possible.
              </p>
            </div>
            <div className="hidden mt-7 lg:mt-4 w-[400px] md:block ">
              <div className="-ml-4 py-2 lg:py-4 ps-4 grid grid-cols-2 gap-2 text-white">
                <div className="flex flex-col space-y-2">
                  <div className="flex items-center">
                    <FaMapLocationDot size={20} />
                    <p className="lg:text-lg ps-2">Location</p>
                  </div>
                  <div className="me-20">
                    <a
                      className="text-xs lg:text-sm"
                      href="https://maps.app.goo.gl/ULpBFJacQKpDaENe8?g_st=com.google.maps.preview.copy"
                    >
                      Zone 56, Building 487, Street 340 Salwa Rd, Qatar
                    </a>
                  </div>
                </div>
                <div className="flex flex-col -ml-4 space-y-2">
                  <div className="flex items-center">
                    <FaPhone />
                    <p className="lg:text-lg ps-2">Contact</p>
                  </div>
                  <div>
                    <a href="tel:+974744425821" className=" text-xs lg:text-sm">
                      Telephone: +974 4487 9792
                    </a>
                    <br />
                    <a href="tel:+97455906131" className="lg:pt-1 text-xs lg:text-sm">
                      Mobile: +974 5590 6131
                    </a>
                  </div>
                </div>
                <div className="flex flex-col mt-5 space-y-2">
                  <div className="flex items-center">
                    <FaWhatsapp size={22} />
                    <p className="ps-2 lg:text-lg">WhatsApp</p>
                  </div>
                  <div>
                    <a
                      href="https://wa.me/97477715423"
                      target="_blank"
                      rel="noopener noreferrer"
                      className=" text-xs lg:text-sm"
                    >
                      +974 77715423
                    </a>
                  </div>
                </div>
                <div className="flex -ml-4 flex-col mt-5 space-y-2">
                  <div className="flex items-center">
                    <CgMail size={22} />
                    <p className="ps-2 lg:text-lg">Email</p>
                  </div>
                  <div>
                    <a
                      href="mailto:info@starkitchenequipments.com"
                      className="text-xs lg:text-sm"
                    >
                      info@starkitchenequipments.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden mt-7 lg:mt-24 xl:block">
            <p className="text-white pt-3 text-xl font-semibold">Location</p>
            <a
              //href="https://maps.app.goo.gl/ULpBFJacQKpDaENe8?g_st=com.google.maps.preview.copy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.14704294615!2d51.44403930000001!3d25.2319719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45d09def67505f%3A0x93a79b243ce3d8aa!2sStar%20Kitchen%20Equipments%20WLL!5e0!3m2!1sen!2sin!4v1724341384210!5m2!1sen!2sin"
                  //style="border:0;"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  className="h-[17.5rem] w-full pt-3"
                ></iframe>
              </div>
            </a>
          </div>

          <div className="mt-6 md:mt-12 lg:mt-[6.5rem] lg:ps-4 2xl:ps-0">
            <ContactForm />
          </div>
          <div className="lg:mt-10 md:hidden w-80">
            <div className="py-4  grid grid-cols-2 gap-1  text-white ">
              <div className="">
                <div className="flex items-center">
                  <FaMapLocationDot size={20} />
                  <a
                    className="text-lg ps-2"
                    href="https://maps.app.goo.gl/ULpBFJacQKpDaENe8?g_st=com.google.maps.preview.copy"
                  >
                    Location
                  </a>
                </div>
                <div>
                  <a
                    href="https://maps.app.goo.gl/ULpBFJacQKpDaENe8?g_st=com.google.maps.preview.copy"
                    className="text-[9px] lg:text-base"
                  >
                    Zone 56, Building، 340، <br /> 487 Salwa Rd, Qatar
                  </a>
                </div>
              </div>
              <div>
                <div className="flex items-center">
                  <FaWhatsapp size={20} />
                  <p className="text-lg ps-2">WhatsApp</p>
                </div>
                <div>
                  <a
                    href="https://wa.me/97477715423"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[9px] lg:text-base"
                  >
                    +974 77715423
                  </a>
                </div>
              </div>
              <div className=" mt-6">
                <div className="flex items-center">
                  <FaPhone size={14} />
                  <p className="text-lg ps-2">Contact</p>
                </div>
                <div>
                  <a
                    href="tel:+97474442582"
                    className="text-[9px] lg:text-base"
                  >
                    Telephone: +974 7444 2582
                  </a>
                  <a
                    href="tel:+97455906131"
                    className="text-[9px] lg:text-base"
                  >
                    Mobile: +974 5590 6131
                  </a>
                </div>
              </div>
              <div className="mt-6">
                <div className="flex items-center">
                  <CgMail size={24} />
                  <p className=" text-lg ps-2">Email</p>
                </div>
                <div>
                  <a
                    href="mailto:info@starkitchenequipments.com"
                    className="text-[9px] lg:text-base"
                  >
                    info@starkitchenequipments.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:mt-10 pt-5 xl:hidden -md:mt-16 px-10 md:px-5 lg:px-28">
          <p className="text-white text-xl font-semibold">Location</p>
          <a
            //href="https://maps.app.goo.gl/ULpBFJacQKpDaENe8?g_st=com.google.maps.preview.copy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.14704294615!2d51.44403930000001!3d25.2319719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45d09def67505f%3A0x93a79b243ce3d8aa!2sStar%20Kitchen%20Equipments%20WLL!5e0!3m2!1sen!2sin!4v1724341384210!5m2!1sen!2sin"
                //style="border:0;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                className="h-72 w-full pt-2"
              ></iframe>
            </div>
          </a>
        </div>
      </div>
      <div className="pt-12">
        <Footer />
      </div>
      <div>
        <a
          href="https://wa.me/97477715423"
          target="_blank"
          rel="noopener noreferrer"
          className="fixed bottom-6 right-4 bg-green-500 text-white rounded-full p-3 shadow-lg hover:bg-green-600"
          style={{ fontSize: "24px" }}
        >
          <FaWhatsapp />
        </a>
      </div>
    </div>
  );
}

export default ContactUs;
