import { axiosUserInstance } from './axiosInstance';
import { getProductsByCategory, getProductById } from './api';


export const fetchProductsByCategory = async (category_id) => {
  try {
    // console.log('idhdhjdjj : ',category_id)
    const response = await axiosUserInstance.get(getProductsByCategory, {
      params: { category_id: category_id },
    });
    // console.log('response.data : ',response.data)
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("An error occurred while fetching products");
  }
};

export const fetchProductById = async (id) => {
  try {
    //console.log('product id : ',id)
    const response = await axiosUserInstance.get(getProductById, {
      params: { id: id },
    });
    //console.log('response.data : ',response.data)
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("An error occurred while fetching products");
  }
};
