import Home from './components/home/Home';
import ContactUs from './components/contact/ContactUs';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import './components/static/styles/styles.css'
import ContactForm from './components/contact/ContactForm';
import AboutUs from './components/About/AboutUs';
import Brands from './components/brands/Brands';
import Products from './components/products/Products';
import ProductDetails from './components/products/ProductDetails';
import ProductDrawer from './components/products/ProductDrawer';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path='/about' element={<AboutUs/>}/>
          <Route path='/brands' element={<Brands/>}/>
          <Route path='/products' element={<Products/>}/>
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path='/drawer' element={<ProductDrawer/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
