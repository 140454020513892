import React from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import Slider from "react-slick";
import slider1 from "../static/images/slider1.png";
import slider2 from "../static/images/slider2.png";

// Sample next arrow component
const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      className="bg-black bg-opacity-50 py-3 px-3"
      style={{ 
        position: 'absolute', 
        right: 10, 
        top: '50%', 
        transform: 'translateY(-50%)', 
        fontSize: '24px', 
        color: '#fff',
        borderRadius:"50%",
        zIndex: 1 
      }}
    >
      <IoIosArrowForward size={36}/>
    </div>
  );
};

// Sample previous arrow component
const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      className="bg-black bg-opacity-50 py-3 px-3"
      style={{ 
        position: 'absolute', 
        left: 10, 
        top: '50%', 
        transform: 'translateY(-50%)', 
        fontSize: '24px', 
        borderRadius:"50%",
        color: '#fff',
        zIndex: 1 
      }}
    >
      <IoIosArrowBack size={36}/>
    </div>
  );
};

function HomeSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500, // Transition speed
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Time between slides
    fade: true, // Enable fade animation
    cssEase: "ease-in-out", // Easing function for smooth animation
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="relative flex-1 mt-20 h-full overflow-hidden">
      <Slider {...settings} className="w-full h-full">
        <div className="w-full h-full">
          <img
            src={slider1}
            alt="Slider 1"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="w-full h-full">
          <img
            src={slider2}
            alt="Slider 2"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="w-full h-full">
          <img
            src={slider2}
            alt="Slider 2"
            className="w-full h-full object-cover"
          />
        </div>
      </Slider>
    </div>
  );
}

export default HomeSlider;
