import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import bg from "../static/images/modern-kitchen-equipment-in-stainless-steel-design-free-photo (1).jpg";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

import { getGategoryById } from "../../services/category";
import {
  fetchProductsByCategory,
  fetchProductById,
} from "../../services/product";

import noProductImage from "../static/images/products/No_Product_Found.png";
import Button from "@mui/material/Button";

function ProductDetails() {
  const { id } = useParams();
  const categoryId = parseInt(id);
  //console.log('categoryId : ',categoryId)

  const [category, setCategory] = useState(null);

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productDetails, setProductDetails] = useState(null);

  if (!categoryId) {
    return <p>Product not found</p>;
  }

  useEffect(() => {
    const fetchCategory = async () => {
      if (!categoryId) return;

      try {
        const data = await getGategoryById(categoryId);
        //console.log("Fetched category data:", data.responseData);
        if (data && data.responseData) {
          setCategory(data.responseData);
        } else {
          //console.error("No responseData found");
        }
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    };

    fetchCategory();
  }, [categoryId]);

  const loadProducts = async () => {
    try {
      setLoading(true);
      const data = await fetchProductsByCategory(categoryId);
      //console.log('data : ', data.responseData);
      setProducts(data.responseData || []);
      if (data.responseData && data.responseData.length > 0) {
        setSelectedProduct(data.responseData[0]);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadProducts();
  }, [categoryId]);

  useEffect(() => {
    const loadProductDetails = async () => {
      if (!selectedProduct?.id) return;

      try {
        const data = await fetchProductById(selectedProduct.id);
        // console.log("Fetched product details:", data.responseData);

        const product = data.responseData[0];

        if (product) {

          setProductDetails({
            id: product.id,
            img: product.image_url,
            name: product.name,
            description: product.description,
          });
        } else {
          console.error("Image URL not found or invalid format.");
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    loadProductDetails();
  }, [selectedProduct]);

  const handleEnquiry = () => {
    const phoneNumber = "97477715423";
    const productName = selectedProduct.name;

    //console.log("product name: ", productName);
    //console.log("id : ",categoryId)

    const productDetailUrl = `${window.location.origin}/product/${categoryId}`;

    const message = `Hello, I would like to enquire about the following product:\n\nProduct: ${productName}\n\nYou can view more details about the product here: ${productDetailUrl}`;

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="bg-black">
      <div>
        <Navbar />
      </div>
      <div className="-mt-2">
        <div className="relative flex flex-col h-96 md:h-full">
          <img
            src={bg}
            className="w-full h-[120%] object-cover"
            alt="Background"
          />
          <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-10">
            <div className="w-full p-8">
              {/* <p className="text-xl lg:text-4xl font-semibold text-white text-center">
                About Us
              </p> */}
            </div>
          </div>
        </div>
        <div className="text-white pt-10 lg:pt-20 px-10 lg:px-32 bg-black">
          <div className="bg-red-600 w-64 h-20 px-5 py-5 font-semibold hidden lg:block absolute right-28 -mt-28">
            <p className="text-white text-sm -mt-1">
              High-quality products crafted to global standards.
            </p>
          </div>
          <div className="-mt-2">
            <div className="text-white pt-10 bg-black">
              <div>
                {category && category.length > 0 ? (
                  <div>
                    <p className="text-lg lg:text-2xl font-semibold text-white text-left">
                      {category[0].category_name || "Category not available"}
                    </p>
                    <div className="line mt-2 bg-red-600 h-0.5 w-1/2 md:w-1/3 lg:w-1/5"></div>
                  </div>
                ) : (
                  <p className="text-lg lg:text-2xl font-semibold text-white text-left">
                    Category not available
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row mt-10 mx-4 lg:mx-24">
        <div className="w-full md:w-1/2 lg:w-1/3 flex flex-col border border-neutral-400 items-center pt-1 lg:pt-5">
          <div className="w-full flex flex-col lg:flex-row lg:flex-wrap items-center lg:justify-center max-h-[250px] lg:max-h-[500px] overflow-y-auto scrollbar-thin">
          {products.length > 0 ? (
              products.map((item, index) => (
                <button
                  key={index}
                  className={`flex justify-between text-sm lg:text-base w-full md:w-full lg:w-[90%] my-1 sm:mx-2 text-left px-4 py-1 lg:py-3 ${
                    selectedProduct?.name === item.name
                      ? "bg-red-600 text-white border-red-600 font-semibold"
                      : "text-neutral-400 hover:text-white hover:bg-red-600 hover:border-red-600"
                  } ${
                    index === products.length - 1
                      ? ""
                      : "border-b-[0.5px] border-neutral-400"
                  }`}
                  onClick={() => setSelectedProduct(item)}
                >
                  {item.name}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    className={`${
                      selectedProduct?.name === item.name
                        ? "fill-white"
                        : "fill-neutral-400 group-hover:fill-white"
                    }`}
                  >
                    <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
                  </svg>
                </button>
              ))
            ) : (
              <div className="h-20 md:h-52 lg:h-64 flex justify-center items-center">
                <p className="text-neutral-600 text-lg lg:text-2xl text-center">
                  No products available
                </p>
              </div>
            )}
          </div>

          <div className="w-full px-5 lg:px-8 text-white hidden md:block">
            <p className="text-left py-4 text-2xl font-semibold lg:font-bold">
              For Enquiry!
            </p>
            <p className="text-sm leading-6 text-neutral-400">
              Please reach out to us for any product-related queries or further
              assistance.
            </p>
            <div className="mt-5">
              <a href="tel:+974744425821" className="text-xs lg:text-sm">
                Telephone: +974 4487 9792
              </a>
              <br />
              <a href="tel:+97455906131" className="lg:pt-1 text-xs lg:text-sm">
                Mobile: +974 5590 6131
              </a>
            </div>
            <Button
              variant="contained"
              onClick={handleEnquiry}
              sx={{
                backgroundColor: "#dc2626",
                borderRadius: "8px",
                fontSize: "11px",
                fontWeight: "bold",
                width: "120px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "15px",
                marginBottom: "20px",
                "&:hover": {
                  backgroundColor: "#dc2626",
                },
              }}
            >
              Enquiry Now
            </Button>
          </div>
        </div>

        <div className="md:ms-5 w-full md:w-1/2 lg:w-2/3">
        {productDetails ? (
            <div key={selectedProduct.label}>
              <div className="relative inline-block mt-10 md:mt-0">
                <img
                  src={selectedProduct.image_url}
                  alt={productDetails.name}
                  className="lg:h-96"
                />
              </div>
              <div className="mt-5">
                <p className="text-base lg:text-2xl font-semibold lg:font-bold uppercase text-white">
                  {productDetails.name}
                </p>
              </div>
              <div className="w-[90%]">
                <p className="text-xs lg:text-sm pt-5 text-white">
                  {productDetails.description}
                </p>
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center h-96">
              <img
                src={noProductImage}
                alt="No products available"
                className="h-48 lg:h-72 opacity-50"
              />
            </div>
          )}
        </div>
        <div className="w-full mt-10 px-5 text-white md:hidden border border-neutral-400">
          <p className="text-left py-4 text-2xl font-bold">For Enquiry!</p>
          <p className="text-sm leading-6 text-neutral-400">
            Please reach out to us for any product-related queries or further
            assistance.
          </p>
          <div className="mt-5">
            <a href="tel:+974744425821" className="text-xs lg:text-sm">
              Telephone: +974 4487 9792
            </a>
            <br />
            <a href="tel:+97455906131" className="lg:pt-1 text-xs lg:text-sm">
              Mobile: +974 5590 6131
            </a>
          </div>
          <Button
            variant="contained"
            onClick={handleEnquiry}
            sx={{
              backgroundColor: "#dc2626",
              borderRadius: "8px",
              fontSize: "10px",
              fontWeight: "bold",
              width: "110px",
              height: "35px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "15px",
              marginBottom: "10px",
              "&:hover": {
                backgroundColor: "#dc2626",
              },
            }}
          >
            Enquiry Now
          </Button>
        </div>
      </div>

      <div className="bg-black pt-10 lg:pt-20 px-10 lg:px-32"></div>
      <div className="pt-1200">
        <Footer />
      </div>
    </div>
  );
}

export default ProductDetails;
