import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import service1 from "../static/images/services1.jpeg";
import service2 from "../static/images/service2.jpeg";
import service3 from "../static/images/service3.jpeg";
import service4 from "../static/images/services4.jpeg";

import { FaPenNib } from "react-icons/fa";
import { GrProjects } from "react-icons/gr";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { TiSpannerOutline } from "react-icons/ti";

function OurServices() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="flex flex-col bg-black justify-center items-center pt-20 lg:pt-10">
        <p className="text-3xl text-white font-light">OUR SERVICES</p>
        <p className="text-sm pt-3 text-white">
          Expert solutions & dedicated support for your needs
        </p>
      </div>
      <div className="px-4 lg:px-20 xl:px-20 2xl:px-60 pb-20 pt-16">
        <div className="grid grid-cols-2 md:grid-cols-4 ">
          <div className="justify-center lg:h-[292px]">
            <img
              src={service1}
              alt="Service 1"
              className="w-full lg:w-[250px] lg:h-[292px] xl:w-full"
            />
          </div>
          <div className="flex flex-col lg:w-[250px] lg:h-[292px] justify-center ps-4 md:px-4 lg:px-5 2xl:px-10">
            <div>
              <p className="text-white">
                <FaPenNib size={23} />
              </p>
              <p className="text-white font-semibold text-xs md:text-lg lg:text-lg pt-3 lg:pt-4 lg:pb-2 xl:pb-0 ">
                Our Consultation
              </p>
            </div>
            <div>
              <p className="text-white tracking-wide text-[10px] lg:text-xs pt-5 md:pt-5 2xl:pt-5 3xl:pt-5 pb-5 md:pb-0 lg:pb-5 xl:pb-10 2xl:pb-10 3xl-pb-0">
                Get customized insights & actionable recommendations to enhance
                your operations & reach your objectives.
              </p>
              <div className="mt-0 2xl:-mt-8 3xl:mt-0">
                <Link
                  to="/about"
                  className="underline hover:text-red-600 text-neutral-400 text-[10px] lg:text-xs font-medium"
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
          <div className="lg:w-[250px] lg:h-[292px] xl:w-full">
            <div className=" lg:h-full">
              <img
                src={service2}
                alt="Service 2"
                className="w-full lg:w-[250px] lg:h-[292px] xl:w-full hidden md:block"
              />
            </div>
            <div className="flex flex-col justify-center md:px-4 lg:px-5 2xl:px-10 pt-5">
              <div className="md:hidden">
                <p className="text-white">
                  <FaPenNib size={23} />
                </p>
                <p className="text-white font-semibold text-xs md:text-lg lg:text-lg pt-3 lg:pt-4 lg:pb-2 xl:pb-0 ">
                  Project Management
                </p>
              </div>
              <div className="md:hidden">
                <p className="text-white tracking-wide text-[10px] lg:text-xs pt-5 md:pt-5 2xl:pt-5 3xl:pt-5 pb-5 md:pb-0 lg:pb-5 xl:pb-10 2xl:pb-10 3xl-pb-0">
                {(() => {
                    const text =
                      "Streamline your projects with expert planning, organization, & coordination to achieve goals on time & within budget.";
                    const words = text.split(" ");
                    return words.length > 14
                      ? words.slice(0, 14).join(" ") + "..."
                      : text;
                  })()}
                </p>
                <div className="mt-0 2xl:-mt-8 3xl:mt-0">
                  <Link
                    to="/about"
                    className="underline hover:text-red-600 text-neutral-400 text-[10px] lg:text-xs font-medium"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="hidden md:block ps-4 md:px-4 lg:px-5 2xl:px-10">
              <div>
                <p className="text-white">
                  <FaPenNib size={23} />
                </p>
                <p className="text-white font-semibold text-xs md:text-lg lg:text-lg pt-3 lg:pt-4 lg:pb-2 xl:pb-0 ">
                  Project Management
                </p>
              </div>
              <div>
                <p className="text-white tracking-wide text-[10px] lg:text-xs pt-5 md:pt-5 2xl:pt-5 3xl:pt-5 pb-5 md:pb-0 lg:pb-5 xl:pb-10 2xl:pb-10 3xl-pb-0">
                {(() => {
                    const text =
                      "Streamline your projects with expert planning, organization, & coordination to achieve goals on time & within budget.";
                    const words = text.split(" ");
                    return words.length > 14
                      ? words.slice(0, 14).join(" ") + "..."
                      : text;
                  })()}
                </p>
                <div className="mt-0 2xl:-mt-8 3xl:mt-0">
                  <Link
                    to="/about"
                    className="underline hover:text-red-600 text-neutral-400 text-[10px] lg:text-xs font-medium"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <img
              src={service2}
              alt="Service 2"
              className="w-full lg:w-[250px] lg:h-[292px] 3xl:w-full 3xl:h-full md:hidden"
            />
          </div>
          <div>
            <div>
              <img
                src={service3}
                alt="Service 3"
                className="w-full lg:w-[250px] lg:h-[292px] 3xl:w-full 3xl:h-full md:hidden"
              />
            </div>
            <div className="flex flex-col h-full justify-center ps-4 md:px-4 lg:px-5 2xl:px-10">
              <div className="hidden md:block">
                <p className="text-white">
                  <FaPenNib size={23} />
                </p>
                <p className="text-white font-semibold text-xs md:text-lg lg:text-lg pt-3 lg:pt-4 lg:pb-2 xl:pb-0 ">
                  Excellent after sales support
                </p>
              </div>
              <div className="hidden md:block">
                <p className="text-white tracking-wide text-[10px] lg:text-xs pt-5 md:pt-5 2xl:pt-5 3xl:pt-5 pb-5 md:pb-0 lg:pb-5 xl:pb-10 2xl:pb-10 3xl-pb-0">
                  Receive expert assistance & solutions post-purchase to ensure
                  continued satisfaction & address any issues.
                </p>
                <div className="mt-0 2xl:-mt-8 3xl:mt-0">
                  <Link
                    to="/about"
                    className="underline hover:text-red-600 text-neutral-400 text-[10px] lg:text-xs font-medium"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div>
            <img
              src={service3}
              alt="Service 3"
              className="w-full lg:w-[250px] lg:h-[292px] 3xl:w-full 3xl:h-full hidden md:block"
            />
            <div className="flex flex-col h-full justify-center ps-4 md:ps-0 md:px-4 lg:px-5 2xl:px-10">
              <div className="md:hidden">
                <p className="text-white">
                  <FaPenNib size={23} />
                </p>
                <p className="text-white font-semibold text-xs md:text-lg lg:text-lg pt-3 lg:pt-4 lg:pb-2 xl:pb-0 ">
                  Excellent after sales support
                </p>
              </div>
              <div className="md:hidden">
                <p className="text-white tracking-wide text-[10px] lg:text-xs pt-5 md:pt-5 2xl:pt-5 3xl:pt-5 pb-5 md:pb-0 lg:pb-5 xl:pb-10 2xl:pb-10 3xl-pb-0">
                  Receive expert assistance & solutions post-purchase to ensure
                  continued satisfaction & address any issues.
                </p>
                <div className="mt-0 2xl:-mt-8 3xl:mt-0">
                  <Link
                    to="/about"
                    className="underline hover:text-red-600 text-neutral-400 text-[10px] lg:text-xs font-medium"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center md:ps-4 md:px-4 lg:px-5 2xl:px-10">
            <div>
              <p className="text-white">
                <FaPenNib size={23} />
              </p>
              <p className="text-white font-semibold text-xs md:text-lg lg:text-lg pt-3 lg:pt-4 lg:pb-2 xl:pb-0 ">
                Installation and Training
              </p>
            </div>
            <div>
              <p className="text-white tracking-wide text-[10px] lg:text-xs pt-5 md:pt-5 2xl:pt-5 3xl:pt-5 pb-5 md:pb-0 lg:pb-5 xl:pb-10 2xl:pb-10 3xl-pb-0">
                Professional setup and comprehensive training to ensure smooth
                operation and effective use of your new system.
              </p>
              <div className="mt-0 2xl:-mt-8 3xl:mt-0">
                <Link
                  to="/about"
                  className="underline hover:text-red-600 text-neutral-400 text-[10px] lg:text-xs font-medium"
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
          <div>
            <img
              src={service4}
              alt="Service 4"
              className="w-full lg:w-[250px] lg:h-[292px] 3xl:w-full 3xl:h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
