import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import bg from "../static/images/modern-kitchen-equipment-in-stainless-steel-design-free-photo (1).jpg";

import { fetchCategories } from "../../services/category";
import noProductImage from "../static/images/products/No_Product_Found.png";
import { FaWhatsapp } from "react-icons/fa";

function Products() {

  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const buttonclick = (categoryId) => {
    //console.log("productId : ", categoryId);
    navigate(`/product/${categoryId}`);
  };

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchCategories();
        //console.log("data : ", data.responseData);
        setCategories(data.responseData);
      } catch (error) {
        //console.error("Error loading categories:", error);
      }
    };
    loadCategories();
  }, []);

  return (
    <div className="bg-black">
      <div>
        <Navbar />
      </div>
      <div className="-mt-2">
        <div className="relative flex flex-col h-96 md:h-full">
          <img
            src={bg}
            className="w-full h-[120%] object-cover"
            alt="Background"
          />
          <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-10">
            <div className="w-full p-8">
              {/* <p className="text-xl lg:text-4xl font-semibold text-white text-center">
                About Us
              </p> */}
            </div>
          </div>
        </div>
        <div className="text-white pt-10 lg:pt-20 px-5 md:px-10 lg:px-10 xl:px-32 bg-black">
          <div className="bg-red-600 w-64 h-20 px-5 py-5 font-semibold hidden lg:block absolute right-28 -mt-28">
            <p className="text-white text-sm -mt-1">
              High-quality products crafted to global standards.
            </p>
          </div>
          <div>
            <p className="text-2xl lg:text-3xl pt-5 font-semibold text-white text-left">
              Categories
            </p>
            <div className="line mt-2 bg-red-600 h-0.5 w-1/3 md:w-1/3 lg:w-56"></div>
            <p className="text-neutral-400 pt-2 text-xs">
              The following categories will be listed in the website
            </p>
            {/* <div className="hidden"> */}
            <div className="flex flex-wrap gap-4 mt-10 justify-center">
              {categories && categories.length === 0 ? (
                <div className="flex justify-center items-center h-96">
                  <img
                    src={noProductImage}
                    alt="No products available"
                    className="h-48 lg:h-72 opacity-50"
                  />
                </div>
              ) : (
                categories?.map((category) => (
                  <div
                    onClick={() => buttonclick(category.id)}
                    key={category.id}
                    className="border border-neutral-400 flex flex-col px-2 h-[6.5rem] w-[30%] md:w-[22%] lg:w-[11%] items-center justify-center text-neutral-400 hover:border-red-600 hover:text-red-600"
                  >
                    <img
                      src={category.icon_url}
                      alt={category.category_name}
                      className="h-10 w-10"
                    />
                    <p className="text-xs text-center pt-1">
                      {category.category_name}
                    </p>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-12">
        <Footer />
      </div>
      <div>
        <a
          href="https://wa.me/97477715423"
          target="_blank"
          rel="noopener noreferrer"
          className="fixed bottom-6 right-4 bg-green-500 text-white rounded-full p-3 shadow-lg hover:bg-green-600"
          style={{ fontSize: "24px" }}
        >
          <FaWhatsapp />
        </a>
      </div>
    </div>
  );
}

export default Products;
