import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { fetchCategories } from "../../services/category";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import Slider from "react-slick";
import slider1 from "../static/images/slider1.png";
import slider2 from "../static/images/slider2.png";

import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import Navbar from "../navbar/Navbar";

import brand1 from "../static/images/brands/brand1.png";
import brand2 from "../static/images/brands/brand2.png";
import brand3 from "../static/images/brands/brand3.png";
import brand4 from "../static/images/brands/brand4.png";
import brand5 from "../static/images/brands/brand5.png";
import brand6 from "../static/images/brands/brand6.png";
import brand7 from "../static/images/brands/brand7.png";
import brand8 from "../static/images/brands/brand8.png";
import brand9 from "../static/images/brands/NTF brand logo.png";
import brand10 from "../static/images/brands/brand10.png";
import brand11 from "../static/images/brands/macpan.svg";
import brand12 from "../static/images/brands/macno 1.png";
import brand13 from "../static/images/brands/LOGO.webp";
import brand14 from "../static/images/brands/logo-header.png";
import brand15 from "../static/images/brands/fimar.png";
import brand17 from "../static/images/brands/Roller-Grill-Logo 1.png";
import brand18 from "../static/images/brands/Berjaya-Logo.png";
import brands19 from "../static/images/brands/Rational_AG_201x_logo.svg.png";
import brands20 from "../static/images/brands/brand16.png";
import brands21 from "../static/images/brands/BAT_LOGO_CAPLAIN_MACHINES_RVB-HORIZ-768x201.png";
import brands22 from "../static/images/brands/logo-2020-lainox2.png";

import client1 from "../static/images/client/client1.png";
import client2 from "../static/images/client/client2.png";
import client3 from "../static/images/client/client3.png";
import client4 from "../static/images/client/almeera logo.svg";
import client5 from "../static/images/client/Samsung-logo.png";
import client6 from "../static/images/client/lulu.png";
import client7 from "../static/images/client/Katara-Hospitality logo.svg";

import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { LuSofa } from "react-icons/lu";
import { IoHammerOutline } from "react-icons/io5";
import { BiSupport } from "react-icons/bi";

import { FaKitchenSet } from "react-icons/fa6";
import { MdOutlineLocalLaundryService } from "react-icons/md";
import { IoRestaurantSharp } from "react-icons/io5";
import { CiBurger } from "react-icons/ci";
import { MdOutlineLocalCafe } from "react-icons/md";
import { GiHospitalCross } from "react-icons/gi";
import { FaWhatsapp } from "react-icons/fa";
import { BsShop } from "react-icons/bs";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../static/styles/styles.css";

import OurBrands from "./OurBrands";
import OurServices from "./OurServices";
import Footer from "../footer/Footer";
import ReviewForm from "./ReviewForm";
import HomeSlider from "./HomeSlider";

const icons = [
  brand1,
  brand2,
  brand3,
  brand4,
  brand5,
  brand6,
  brand7,
  brand10,
  brand11,
  brand13,
  brand15,
  brand18,
  brands19,
  brands21,
];

const icons2 = [brands20, brand9, brand12, brand8];

const icons3 = [brand14, brand17];

const clients = [
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  client7,
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  client7,
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  client7,
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  client7,
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  client7,
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  client7,
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  client7,
];

const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      className="bg-black bg-opacity-50 py-2 px-2 cursor-pointer"
      style={{
        position: "absolute",
        right: 10,
        top: "50%",
        transform: "translateY(-50%)",
        fontSize: "24px",
        color: "#fff",
        borderRadius: "50%",
        zIndex: 1,
      }}
    >
      <IoIosArrowForward size={24} />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      className="bg-black bg-opacity-50 py-2 px-2 cursor-pointer"
      style={{
        position: "absolute",
        left: 10,
        top: "50%",
        transform: "translateY(-50%)",
        fontSize: "24px",
        borderRadius: "50%",
        color: "#fff",
        zIndex: 1,
      }}
    >
      <IoIosArrowBack size={24} />
    </div>
  );
};

const Home = () => {

  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    cssEase: "ease-in-out",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const value = 5;

  const { ref: counterRef, inView: isCounterVisible } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   const updateDisplayedProducts = () => {
  //     const screenWidth = window.innerWidth;

  //     if (screenWidth < 640) {
  //       setDisplayedProducts(products.slice(0, 9));
  //     } else if (screenWidth >= 640 && screenWidth < 1024) {
  //       setDisplayedProducts(products.slice(0, 12));
  //     } else {
  //       setDisplayedProducts(products);
  //     }
  //   };

  //   updateDisplayedProducts();
  //   window.addEventListener("resize", updateDisplayedProducts);

  //   return () => window.removeEventListener("resize", updateDisplayedProducts);
  // }, [products]);

  const buttonclick = (categoryId) => {
    //console.log("productId : ", categoryId);
    navigate(`/product/${categoryId}`);
  };

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchCategories();
        //console.log("data : ", data.responseData);
        setCategories(data.responseData);
      } catch (error) {
        //console.error("Error loading categories:", error);
      }
    };
    loadCategories();
  }, []);

  return (
    <div className="bg-black">
      <div className="lg:h-screen flex flex-col ">
        <div>
          <Navbar />
        </div>
        <div className="relative flex-1 mt-20 h-full overflow-hidden">
          <Slider {...settings} className="w-full h-full">
            <div className="w-full h-full">
              <img
                src={slider1}
                alt="Slider 1"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="w-full h-full">
              <img
                src={slider2}
                alt="Slider 2"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="w-full h-full">
              <img
                src={slider2}
                alt="Slider 2"
                className="w-full h-full object-cover"
              />
            </div>
          </Slider>
        </div>
      </div>
      <div className="bg-[#dc242c] w-full -mt-4 flex flex-col lg:flex-row justify-between px-10 md:px-20 py-10">
        <div className="flex flex-col lg:w-1/3">
          <div className="md:w-1/2 lg:w-full">
            <p className="text-white text-2xl lg:text-4xl text-left font-semibold ">
              Our Dedication to <br /> Your Satisfaction
            </p>
          </div>
          <div className="flex flex-col md:flex-row lg:flex-col">
            <div className="pt-4 md:pt-6">
              <p className="text-left text:sm md:text-base text-white tracking-wide">
                Our mission is to ensure your satisfaction with exceptional
                service and attention to detail. We’re committed to exceeding
                your expectations.
              </p>
            </div>
            <div className="pt-4 md:pt-6">
              <Box
                sx={{
                  width: 200,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Rating
                  name="text-feedback"
                  value={value}
                  readOnly
                  precision={0.5}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
              </Box>
              <p className="lg:pt-3 text-white font-bold">
                4.9 / <span className="font-normal"> 5 rating</span>
              </p>
              <p className="text-white lg:pt-3 font-semibold">Quality</p>
            </div>
          </div>
        </div>
        <div className="lg:w-2/5 pt-8 md:pt-16 lg:pt-0 w-full text-white flex flex-col justify-center">
          <div className="flex justify-center items-start">
            <p className="pt-2">
              <LuSofa size={24} />
            </p>
            <div className="px-6">
              <p className="font-bold text-lg lg:text-2xl">
                Quality Craftsmanship
              </p>
              <p className="pt-1 text-sm">
                Our commitment to quality craftsmanship ensures every product is
                meticulously crafted for durability and excellence.
              </p>
            </div>
          </div>
          <div className="flex pt-6">
            <p className="pt-2">
              <IoHammerOutline size={24} />
            </p>
            <div className="px-6">
              <p className="font-bold text-lg lg:text-2xl">
                Extensive Selection
              </p>
              <p className="pt-1 text-sm">
                Explore our extensive selection, where you’ll find a wide range
                of carefully curated products to suit every need and preference
              </p>
            </div>
          </div>
          <div className="flex pt-6">
            <p className="pt-2">
              <BiSupport size={24} />
            </p>
            <div className="px-6">
              <p className="font-bold text-lg lg:text-2xl">
                Dedicated Customer Support
              </p>
              <p className="pt-1 text-sm">
                Our dedicated customer support team is here to assist you with
                any inquiries and ensure a seamless experience
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* our brands */}
      <div className="bg-black lg:pt-12">
        <div className="bg-black md:h-full h-screen ">
          <OurBrands showReadMore={true} />
        </div>
        <div className="bg-black xs:mt-10 px-4 md:px-20 lg:px-40">
          <div className="mt-28 sc375:mt-72 iphoneX:mt-40 iphone12:mt-36 iphone14promax:mt-10 iphone13promax:mt-5 sc432:mt-0 sc396:mt-14 xs:mt-16 sc460:mt-0 sm:mt-16 md:mt-44 lg:mt-40">
            <div className="bg-black">
              <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-5 gap-12 lg:gap-y-[4rem]">
                {icons.map((icon, index) => (
                  <div
                    key={index}
                    className="flex lg:h-14 justify-center items-center"
                  >
                    <img
                      src={icon}
                      alt={`Brand ${index + 1}`}
                      className="h-8 w-auto"
                    />
                  </div>
                ))}
                {icons2.map((icon, index) => (
                  <div key={index} className="flex justify-center items-center">
                    <img
                      src={icon}
                      className="max-w-full max-h-8 lg:max-h-10 object-contain"
                    />
                  </div>
                ))}
                {icons3.map((icon, index) => (
                  <div key={index} className="flex justify-center items-center">
                    <img
                      src={icon}
                      className="max-w-full max-h-8 lg:max-h-12 object-contain"
                    />
                  </div>
                ))}
                <div className="flex justify-center items-center md:hidden">
                  <img
                    src={brands22}
                    className="max-w-full max-h-8 lg:max-h-10 object-contain"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-black w-full flex justify-center mt-10 lg:mt-20">
          <Link
            to="/brands"
            className="border text-white hover:text-red-500 hover:border-red-600 px-8 py-2 text-lg"
          >
            See more
          </Link>
        </div>
      </div>
      {/* End of our brands */}

      {/* Our service */}
      <div className="bg-black pt-4 md:pt-5 lg:pt-6">
        <div>
          <OurServices />
        </div>
        {/* End of service */}

        {/* Products */}
        <div className="md:pt-5 lg:pt-6 lg:px-20 xl:px-20 2xl:px-40 ">
          <div className="flex flex-col justify-center items-center">
            <p className="text-3xl text-white font-light">PRODUCTS</p>
            <p className="text-sm pt-3 text-white">
              High-quality products crafted to global standards.
            </p>
          </div>
          <div className="">
            <div className="flex items-center justify-center w-full px-4 md:px-0">
              <div className="flex flex-wrap gap-4 mt-10 justify-center">
                {categories?.map((category) => (
                  <div
                    onClick={() => buttonclick(category.id)}
                    key={category.id}
                    className="border border-neutral-400 flex flex-col h-[6.5rem] w-[30%] md:w-[22%] lg:w-[14.5%] px-2 items-center justify-center text-neutral-400 hover:border-red-600 hover:text-red-600"                  
                  >
                    <img
                      src={category.icon_url}
                      alt={category.category_name}
                      className="h-10 w-10"
                    />
                    <p className="text-xs text-center px-5 pt-1">
                      {category.category_name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="hidden">
            <div className="flex items-center justify-center w-full px-4">
              <div className="flex flex-wrap gap-4 mt-10 justify-center">
                {categories?.map((category) => (
                  <div
                    onClick={() => buttonclick(category.id)}
                    key={category.id}
                    className="border border-neutral-400 flex flex-col px-2 h-[6.5rem] w-[30%] md:w-[22%] lg:w-[11%] items-center justify-center text-neutral-400 hover:bg-red-600 hover:border-red-600 hover:text-white"
                  >
                    <img
                      src={`https://drive.google.com/thumbnail?id=${category.icon_url}&sz=500-h500`}
                      alt={category.category_name}
                      className="h-10 w-10"
                    />
                    <p className="text-xs text-center pt-1">
                      {category.category_name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-16">
            <Link
              to="/products"
              className="border text-white hover:text-red-500 hover:border-red-600 px-8 py-2 text-lg"
            >
              See more
            </Link>
          </div>
        </div>
        {/* End of Products */}

        <div
          ref={counterRef}
          className="bg-red-600 w-full flex justify-around px-6 md:px-10 mt-10 lg:mt-10 lg:px-40 py-6 lg:py-10"
        >
          <div className="text-center text-white items-center flex flex-col justify-center">
            <p className="font-bold text-2xl lg:text-3xl">
              {isCounterVisible && (
                <CountUp
                  end={2000}
                  duration={2}
                  suffix="+"
                  className="hidden md:block"
                />
              )}
              {isCounterVisible && (
                <CountUp
                  end={2}
                  duration={2}
                  suffix="K +"
                  className="md:hidden"
                />
              )}
            </p>
            <p className="pt-2 hidden md:block">Kitchen Products</p>
            <p className="pt-2 md:hidden">Products</p>
          </div>
          <div className="text-center text-white">
            <p className="font-bold text-2xl lg:text-3xl">
              {isCounterVisible && (
                <CountUp
                  end={2000}
                  duration={2}
                  suffix="+"
                  className="hidden md:block"
                />
              )}
              {isCounterVisible && (
                <CountUp
                  end={2}
                  duration={2}
                  suffix="K +"
                  className="md:hidden"
                />
              )}
            </p>
            <p className="pt-2">Clients</p>
          </div>
          <div className="text-center text-white">
            <p className="font-bold text-2xl lg:text-3xl">
              {isCounterVisible && <CountUp end={15} duration={2} suffix="+" />}
            </p>
            <p className="pt-2 hidden md:block">Years of Experience</p>
            <p className="pt-2 md:hidden">Years</p>
          </div>
        </div>
      </div>
      {/* End of Our service */}

      {/* Our clients */}
      <div className="bg-black mt-5">
        <p className="text-3xl pt-10 lg:pt-20 text-white font-light text-center mb-6">
          OUR CLIENTS
        </p>
        <div className="overflow-hidden relative">
          <div className="flex space-x-8 animate-scroll">
            {clients.map((client, index) => (
              <div key={index} className="flex-shrink-0">
                <img
                  src={client}
                  alt={`Client ${index + 1}`}
                  className="h-10 w-auto"
                />
              </div>
            ))}
            {clients.map((client, index) => (
              <div key={index + clients.length} className="flex-shrink-0">
                <img
                  src={client}
                  alt={`Client ${index + 1}`}
                  className="h-10 w-auto"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End of Our clients */}

      {/* SERVE INDUSTRIES */}
      <div className="bg-black pt-10 lg:pt-20 pb-5 w-full flex flex-col items-center justify-center">
        <p className="text-white text-2xl">WE SERVE INDUSTRIES</p>
        <p className="text-white text-center text-sm pt-3">
          Customized services to meet the unique needs of various industries.
        </p>
        <div className="flex flex-wrap justify-center text-neutral-400 pt-10 lg:pt-14 gap-4">
          <div className="flex flex-col items-center text-center border-r pr-4">
            <p className="text-3xl sm:text-4xl mb-1">
              <FaKitchenSet />
            </p>
            <p className="text-xs sm:text-sm">COMMERCIAL KITCHEN</p>
          </div>
          <div className="flex flex-col items-center text-center border-r ps-2 pr-4 md:ps-6 md:pr-8">
            <p className="text-3xl sm:text-4xl mb-1">
              <MdOutlineLocalLaundryService />
            </p>
            <p className="text-xs sm:text-sm">LAUNDRY</p>
          </div>
          <div className="flex flex-col items-center text-center border-r ps-2 pr-4 md:ps-6 md:pr-8">
            <p className="text-3xl sm:text-4xl mb-1">
              <IoRestaurantSharp />
            </p>
            <p className="text-xs sm:text-sm">RESTAURANTS</p>
          </div>
          <div className="flex flex-col items-center text-center border-r ps-2 pr-4 md:ps-6 md:pr-8">
            <p className="text-3xl sm:text-4xl mb-1">
              <CiBurger />
            </p>
            <p className="text-xs sm:text-sm">BAKERY</p>
          </div>
          <div className="flex flex-col items-center text-center border-r ps-2 pr-4 md:ps-6 md:pr-8">
            <p className="text-3xl sm:text-4xl mb-1">
              <MdOutlineLocalCafe />
            </p>
            <p className="text-xs sm:text-sm">CAFETERIA</p>
          </div>
          <div className="flex flex-col items-center text-center border-r ps-2 pr-4 md:ps-6 md:pr-8">
            <p className="text-3xl sm:text-4xl mb-1">
              <MdOutlineLocalLaundryService />
            </p>
            <p className="text-xs sm:text-sm">HOSPITALITY</p>
          </div>
          <div className="flex flex-col items-center text-center border-r ps-2 pr-4 md:ps-6 md:pr-8">
            <p className="text-3xl sm:text-4xl mb-1">
              <GiHospitalCross />
            </p>
            <p className="text-xs sm:text-sm">COLD STORAGE</p>
          </div>
          <div className="flex flex-col items-center text-center ps-2 pr-4 md:ps-6 md:pr-8">
            <p className="text-3xl sm:text-4xl mb-1">
              <BsShop />
            </p>
            <p className="text-xs sm:text-sm">HYPER MARKET</p>
          </div>
        </div>
      </div>
      {/*END SERVE INDUSTRIES */}

      {/* Product Review */}
      <div className="pt-10 lg:pt-20 bg-black">
        <ReviewForm />
      </div>
      <div>
        <Footer />
      </div>

      {/* WhatsApp Chat Button */}
      <div>
        <a
          href="https://wa.me/97477715423"
          target="_blank"
          rel="noopener noreferrer"
          className="fixed bottom-6 right-4 bg-green-500 text-white rounded-full p-3 shadow-lg hover:bg-green-600"
          style={{ fontSize: "24px" }}
        >
          <FaWhatsapp />
        </a>
      </div>
    </div>
  );
};

export default Home;
